<template>
  <section>
    <b-container class="py-md-4">
      <div :class="[style['register_wrapper'], 'my-md-5 my-4 p-3 text-white']">
        <router-link
          to="/"
          class="navbar-brand font-weight-bold text-center my-6 px-2 d-none d-lg-inline-block"
        >
          <img
            :src="logo"
            alt="fanpopz-logo"
            fluid
            class="register-logo"
          >
        </router-link>
        <b-row class="justify-content-center justify-content-lg-start">
          <b-col
            xl="8"
            lg="8"
          >
            <div class="text-center text-lg-left pt-5 mb-5">
              <img
                class="mb-3"
                src="../assets/imagesv2/ic-forgot-password.png"
                alt=""
              >
              <h1 class="title display-5 font-weight-bold mb-4">
                Forgot Password
              </h1>
              <p class="font-open-sans mb-1">
                Enter email address you used to register.
              </p>
              <p class="font-open-sans mb-0">
                We’ll send you a verification code and link to create a new password.
              </p>
            </div>
            <b-row :class="['mb-5 justify-content-center justify-content-lg-start', style['forgot-pwd-form']]">
              <b-col
                xl="8"
                lg="10"
                class="pl-md-0"
              >
                <div class="font-open-sans">
                  <div class="bank-search-input">
                    <b-input-group class="mt-3">
                      <b-form-input
                        id="email"
                        v-model="email"
                        v-validate="{ required: true, email: true }"
                        placeholder="Type your email address"
                        type="email"
                        name="email"
                        :state="validateState('email')"
                        data-vv-as="Email"
                        required
                        class="form-control border-left-0 border-right-0 border-top-0 bg-transparent join-community"
                      />
                      <b-form-invalid-feedback id="email">
                        {{ veeErrors.first("email") }}
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </div>
                </div>
                <b-overlay
                  :show="isLoading"
                  rounded
                  opacity="0.6"
                  spinner-small
                  spinner-variant="primary"
                  class="mt-4"
                >
                  <b-button
                    ref="button"
                    :disabled="isLoading"
                    variant="golden"
                    class="btn-pop text-uppercase font-weight-bold bg-golden"
                    squared
                    block
                    @click="handleNext"
                  >
                    CONFIRM
                  </b-button>
                </b-overlay>
              </b-col>
            </b-row>
            <div class="text-right mb-5" />
          </b-col>
        </b-row>
      </div>
      <ConfirmAccount v-if="showModal" />
    </b-container>
  </section>
</template>

<script>
import axios from "axios";
import { API_BASE_URL } from "../config/config";
import { _sendResponse } from "../js/common-function";
import { APIUrlList } from "../js/api_url";
import style from "../styles/registration.module.scss";
import ConfirmAccount from "../components/Common/ConfirmEmail.vue";
import logo from "../assets/logo/logo-white-text.png";
export default {
    name: "ForgotPassword",
    components: {
        ConfirmAccount,
    },
    data() {
        return {
            logo,
            style,
            url: null,
            email: "",
            isLoading: false, // join button click load
        };
    },
    methods: {
        validateState(ref) {
            if (
                this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
            ) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        handleNext() {
            this.isLoading = true;
            this.$validator.validateAll().then(result => {
                if (!result) {
                    this.isLoading = false;
                    return;
                }
                this.isLoading = true;
                let formData = new FormData();
                formData.append("email", this.email);
                axios
                    .post(API_BASE_URL + APIUrlList.SENDVERIFICATIONCODE, formData)
                    .then((response) => {
                        if (response.data.status === false) {
                            _sendResponse("error", response.data.message);
                            return;
                        }
                        _sendResponse("success", response.data.message);
                        this.$router.push('/');
                    })
                    .catch((error) => {
                        _sendResponse("error", error.response.data.errors);
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            });
        },
    },
};
</script>
