<template>
  <b-modal
    id="modal-center"
    centered
    :visible="true"
    hide-footer
    size="lg"
    body-class="text-center pb-5"
    header-class="border-bottom-0"
    @hidden="()=>this.$router.push('/')"
  >
    <template
      v-slot:modal-header=""
    >
      <b-button
        type="button"
        variant="default"
        class="p-0 ml-auto"
        @click="redirectHome"
      >
        <icon-close
          height="24"
          width="24"
        />
      </b-button>
    </template>
    <img
      src="../../assets/imagesv2/icon_email.png"
      alt=""
    >
    <p class="display-4 font-weight-bold">
      Check your email
    </p>
    <b-row class="justify-content-center mb-3">
      <b-col
        xl="5"
        lg="7"
        class="text-center confirm-email-text font-open-sans"
      >
        An e-mail has been sent with details on how to reset your password.
      </b-col>
    </b-row>
    <router-link
      to="/"
      class="btn btn-golden rounded-pill px-5 mb-4"
    >
      OK
    </router-link>
  </b-modal>
</template>

<script>
import IconClose from '../../assets/svg-import/close.svg';
export default {
    name:'Logout',
    components: {IconClose},
    methods: {
        redirectHome(){
            window.location.href = "/";
        }
    }
};
</script>
